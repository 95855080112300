import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import HeaderSEO from '../../../components/partials/HeaderSEO'
import Layout from '../../../components/Layout'
import Spacing from '../../../components/partials/Spacing'
import Form from '../../../components/forms/Form'

import { getCookieByName } from '../../../helpers/getCookieByName'

const research451 = () => {
  const data = useStaticQuery(graphql`
    query Research_451_SEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "451_Research" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
      allContentfulAsset(
        filter: { id: { eq: "f17915f7-fd84-5f2f-ba2a-21c2cda296d0" } }
      ) {
        edges {
          node {
            id
            file {
              url
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  const onClick = () => {
    document.querySelector<HTMLInputElement>('#splunk_id').value =
      getCookieByName('mintjs%3Auuid')
    document.querySelector<HTMLInputElement>('#formsubmit').disabled = true
  }

  return (
    <Layout
      newsletter={false}
      hasBanner={true}
      logo={'451_logo'}
      onlyLogo={true}
    >
      <HeaderSEO pageSEO={pageSEO} />

      <section className="overflow-hidden container-1 hero-margin">
        <div className="text-center">
          <h1 className="h4-like pb-0">RESEARCH REPORT</h1>
          <div className="d-flex flex-wrap justify-content-center">
            <h2 className="h1-like pb-0">
              Does DataOps fuel the success of modern data platform initiatives?
            </h2>
          </div>
        </div>

        <div className="my-4 py-4"></div>
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-6 col-10 col-md-9 pt-5">
              <StaticImage
                className="w-75"
                src="../../../resources/images/451_page/preview_451.png"
                placeholder="tracedSVG"
                alt="Preview 451"
              />
              <h2 className="hero-description-dark pt-1 mobile-padding-right ">
                <div className="mb-2 mt-4 fw-600">
                  ‘DataOps’ promises the holy grail of operational efficiency to
                  enable businesses to make data-driven decisions. Lenses.io &
                  451 Research explore the following questions with enterprises:
                </div>
              </h2>
              <div>
                <ul className="red-checked-list">
                  <li>
                    <span className="f-18 p-text pb-3">
                      What does DataOps mean in principle & reality?
                    </span>
                  </li>
                  <li>
                    <span className="f-18 p-text pb-3">
                      Will it move beyond the hype cycle to deliver business
                      impact?
                    </span>
                  </li>
                  <li>
                    <span className="f-18 p-text pb-3">
                      What’s the current adoption-rate of DataOps practices?
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="px-0 col-lg-4 offset-lg-2 offset-0 col-sm-7 col-10 pt-5 mobile-margin-bottom">
              <div className="footer-color py-5 px-2 rounded">
                <div className="d-flex flex-wrap">
                  <div className="col-md-12 mobile-padding-reset">
                    {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
                    <Form
                      formId="zoominfo-default-form"
                      action="https://go.pardot.com/l/877242/2020-08-04/4fkdbt"
                      onSubmit={onClick}
                      inputId="formsubmit"
                      inputValue="Download Now"
                      firstName={'required'}
                      lastName={'required'}
                      email={'required'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* BG COVER */}
      </section>
      <Spacing />
    </Layout>
  )
}

export default research451
