import React, { useState, useEffect, Fragment } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import PropTypes from 'prop-types'
import ZoominfoFields from '../../components/partials/Zoominfo-fields'

import { getClientId } from '../../helpers/getClientId'
import callZoominfo from '../../helpers/zoomInfo'
import { getCountry } from '../../helpers/getCountry'
import axios from 'axios'
import * as CompanyEmailValidator from 'company-email-validator'

const Form = ({
    formId,
    action,
    onSubmit,
    firstName,
    lastName,
    email,
    company,
    jobTitle,
    country,
    phone,
    message,
    messageValue,
    inputId,
    inputValue,
    type,
    trial_source,
    validateEmailFlag,
    contactSource,
    demoRequest,
}) => {
    useEffect(() => {
        // Assisting Scripts
        try {
            // Zoominfo Snippet - Binding by formID
            if (formId === 'zoominfo-form-with-phone') {
                callZoominfo('pJHlgY8C79g4kici9R8C')
            } else if (formId === 'zoominfo-default-form') {
                callZoominfo('qeaakHWCTtKYuT98MCW4')
            }
            // Getting clientId
            const interval = setInterval(function () {
                if (getClientId()) {
                    let gaClientId = getClientId()
                    document.querySelector('#ga_client_id').value = gaClientId
                    clearInterval(interval)
                }
            }, 1000)
            // Getting country name for routing
            getCountry()
        } catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement('script')
        script.src =
            'https://www.google.com/recaptcha/api.js?render=6Ldy0-UZAAAAAJT6bzr6y5NXI9lQ7XJ14O3o6tjF'
        script.addEventListener('load', handleLoaded)
        document.body.appendChild(script)
    }, [])

    const [disabledButton, setDisabledButton] = useState(true)

    // Extra control to prevent enabling button without having triggered recaptcha
    // Needed to prevent non-professional email submission
    let [passedRecaptcha, setPassedRecaptcha] = useState(false)

    const validateEmail = () => {
        const email = document.querySelector('#email')
        if (!CompanyEmailValidator.isCompanyEmail(email.value)) {
            email.classList.add('is-invalid')
            document.querySelector('#formsubmit').disabled = true
        } else {
            email.classList.remove('is-invalid')
            if (passedRecaptcha === true) {
                document.querySelector('#formsubmit').disabled = false
            }
        }
    }

    const reCAPTCHATriggered = () => {
        // On Business Emails
        if (validateEmailFlag) {
            try {
                document.querySelector('#formsubmit').disabled = false
                setPassedRecaptcha(true)
                validateEmail()
            } catch (error) {
                console.log(error)
            }
            // Default Behaviour
        } else {
            document.querySelector('#formsubmit').disabled = false
        }
    }

    const handleLoaded = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute('6Ldy0-UZAAAAAJT6bzr6y5NXI9lQ7XJ14O3o6tjF', {
                    action: 'verify',
                })
                .then((token) => {
                    try {
                        axios
                            .post(
                                `https://m03u65lddf.execute-api.eu-west-2.amazonaws.com/reCaptchaWebsiteScore?token=${token}`,
                            )
                            .then(function (res) {
                                const data = res.data
                                let score = data.response.score
                                if (score > 0.8) {
                                    // pass
                                    setDisabledButton(false)
                                    setPassedRecaptcha(true)
                                }
                            })
                    } catch (error) {
                        console.log(error)
                    }
                })
        })
    }

    return (
        <form id={formId} action={action} acceptCharset='UTF-8' method='POST' onSubmit={onSubmit}>
            {firstName && (
                <input
                    className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                    type='text'
                    required
                    id='first_name'
                    maxLength={80}
                    name='First Name'
                    placeholder='First Name*'
                />
            )}
            {lastName && (
                <input
                    className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                    type='text'
                    required
                    id='last_name'
                    maxLength={80}
                    name='Last Name'
                    placeholder='Last Name*'
                />
            )}
            {email && (
                <Fragment>
                    <input
                        className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                        type='email'
                        required
                        id='email'
                        maxLength={100}
                        name='Email'
                        placeholder='Email*'
                        onKeyUp={validateEmailFlag && validateEmail}
                    />

                    {validateEmailFlag && (
                        <div className='invalid-feedback ml-2'>
                            Please provide your professional email
                        </div>
                    )}
                </Fragment>
            )}
            {company && company === 'required' ? (
                <input
                    className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                    type='text'
                    required
                    id='company'
                    maxLength={100}
                    name='Company'
                    placeholder='Company*'
                />
            ) : company === 'notRequired' ? (
                <input
                    className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                    type='text'
                    id='company'
                    maxLength={100}
                    name='Company'
                    placeholder='Company'
                />
            ) : (
                ''
            )}
            {jobTitle && jobTitle === 'required' ? (
                <input
                    className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                    type='text'
                    required
                    maxLength={100}
                    name='Job Title'
                    id='job_title'
                    placeholder='Job Title*'
                />
            ) : jobTitle === 'notRequired' ? (
                <input
                    className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                    type='text'
                    maxLength={30}
                    name='Job Title'
                    id='job_title'
                    placeholder='Job Title'
                />
            ) : (
                ''
            )}

            <div>
                {country && country === 'required' ? (
                    <select
                        className='footer-color w-100 pl-1 input-form-border py-2 my-2 countryDropdown'
                        name='Country Code'
                        id='country'
                        required
                        defaultValue={''}>
                        <option value='' disabled>
                            Country*
                        </option>
                        <option value='AFG&#x20;&#x2b;93'>AFG +93</option>
                        <option value='ALB&#x20;&#x2b;355'>ALB +355</option>
                        <option value='ARG&#x20;&#x2b;54'>ARG +54</option>
                        <option value='AUS&#x20;&#x2b;61'>AUS +61</option>
                        <option value='AUT&#x20;&#x2b;43'>AUT +43</option>
                        <option value='BEL&#x20;&#x2b;32'>BEL +32</option>
                        <option value='BIH&#x20;&#x2b;387'>BIH +387</option>
                        <option value='BRA&#x20;&#x2b;55'>BRA +55</option>
                        <option value='BG&#x20;&#x2b;359'>BGR +359</option>
                        <option value='CA&#x20;&#x2b;1'>CAN +1</option>
                        <option value='CH&#x20;&#x2b;41'>CHE +41</option>
                        <option value='CHL&#x20;&#x2b;56'>CHL +56</option>
                        <option value='CN&#x20;&#x2b;86'>CHN +86</option>
                        <option value='CO&#x20;&#x2b;57'>COL +57</option>
                        <option value='CR&#x20;&#x2b;506'>CRI +506</option>
                        <option value='CY&#x20;&#x2b;357'>CYP +357</option>
                        <option value='CZ&#x20;&#x2b;420'>CZE +420</option>
                        <option value='DE&#x20;&#x2b;49'>DEU +49</option>
                        <option value='DK&#x20;&#x2b;45'>DNK +45</option>
                        <option value='EG&#x20;&#x2b;20'>EGY +20</option>
                        <option value='ES&#x20;&#x2b;34'>ESP +34</option>
                        <option value='EE&#x20;&#x2b;372'>EST +372</option>
                        <option value='FI&#x20;&#x2b;358'>FIN +358</option>
                        <option value='FR&#x20;&#x2b;33'>FRA +33</option>
                        <option value='GR&#x20;&#x2b;30'>GRC +30</option>
                        <option value='HR&#x20;&#x2b;385'>HRV +385</option>
                        <option value='IND&#x20;&#x2b;91'>IND +91</option>
                        <option value='IS&#x20;&#x2b;354'>ISL +354</option>
                        <option value='IT&#x20;&#x2b;39'>ITA +39</option>
                        <option value='JP&#x20;&#x2b;81'>JPN +81</option>
                        <option value='NL&#x20;&#x2b;31'>NLD +31</option>
                        <option value='NZ&#x20;&#x2b;64'>NZL +64</option>
                        <option value='NG&#x20;&#x2b;234'>NGA +234</option>
                        <option value='NO&#x20;&#x2b;47'>NOR +47</option>
                        <option value='PT&#x20;&#x2b;351'>PRT +351</option>
                        <option value='RU&#x20;&#x2b;7'>RUS +7</option>
                        <option value='TR&#x20;&#x2b;90'>TUR +90</option>
                        <option value='UA&#x20;&#x2b;380'>UKR +380</option>
                        <option value='UK&#x20;&#x2b;44'>UK +44</option>
                        <option value='US&#x20;&#x2b;1'>USA +1</option>
                        <option value='other'>Other</option>
                    </select>
                ) : country === 'notRequired' ? (
                    <select
                        className='footer-color w-100 pl-1 input-form-border py-2 my-2 countryDropdown'
                        name='Country Code'
                        id='country'
                        defaultValue={'default'}>
                        <option value='default' disabled>
                            Country
                        </option>
                        <option value='AFG&#x20;&#x2b;93'>AFG +93</option>
                        <option value='ALB&#x20;&#x2b;355'>ALB +355</option>
                        <option value='ARG&#x20;&#x2b;54'>ARG +54</option>
                        <option value='AUS&#x20;&#x2b;61'>AUS +61</option>
                        <option value='AUT&#x20;&#x2b;43'>AUT +43</option>
                        <option value='BEL&#x20;&#x2b;32'>BEL +32</option>
                        <option value='BIH&#x20;&#x2b;387'>BIH +387</option>
                        <option value='BRA&#x20;&#x2b;55'>BRA +55</option>
                        <option value='BG&#x20;&#x2b;359'>BGR +359</option>
                        <option value='CA&#x20;&#x2b;1'>CAN +1</option>
                        <option value='CH&#x20;&#x2b;41'>CHE +41</option>
                        <option value='CHL&#x20;&#x2b;56'>CHL +56</option>
                        <option value='CN&#x20;&#x2b;86'>CHN +86</option>
                        <option value='CO&#x20;&#x2b;57'>COL +57</option>
                        <option value='CR&#x20;&#x2b;506'>CRI +506</option>
                        <option value='CY&#x20;&#x2b;357'>CYP +357</option>
                        <option value='CZ&#x20;&#x2b;420'>CZE +420</option>
                        <option value='DE&#x20;&#x2b;49'>DEU +49</option>
                        <option value='DK&#x20;&#x2b;45'>DNK +45</option>
                        <option value='EG&#x20;&#x2b;20'>EGY +20</option>
                        <option value='ES&#x20;&#x2b;34'>ESP +34</option>
                        <option value='EE&#x20;&#x2b;372'>EST +372</option>
                        <option value='FI&#x20;&#x2b;358'>FIN +358</option>
                        <option value='FR&#x20;&#x2b;33'>FRA +33</option>
                        <option value='GR&#x20;&#x2b;30'>GRC +30</option>
                        <option value='HR&#x20;&#x2b;385'>HRV +385</option>
                        <option value='IND&#x20;&#x2b;91'>IND +91</option>
                        <option value='IS&#x20;&#x2b;354'>ISL +354</option>
                        <option value='IT&#x20;&#x2b;39'>ITA +39</option>
                        <option value='JP&#x20;&#x2b;81'>JPN +81</option>
                        <option value='NL&#x20;&#x2b;31'>NLD +31</option>
                        <option value='NZ&#x20;&#x2b;64'>NZL +64</option>
                        <option value='NG&#x20;&#x2b;234'>NGA +234</option>
                        <option value='NO&#x20;&#x2b;47'>NOR +47</option>
                        <option value='PT&#x20;&#x2b;351'>PRT +351</option>
                        <option value='RU&#x20;&#x2b;7'>RUS +7</option>
                        <option value='TR&#x20;&#x2b;90'>TUR +90</option>
                        <option value='UA&#x20;&#x2b;380'>UKR +380</option>
                        <option value='UK&#x20;&#x2b;44'>UK +44</option>
                        <option value='US&#x20;&#x2b;1'>USA +1</option>
                        <option value='other'>Other</option>
                    </select>
                ) : (
                    ''
                )}

                {phone && phone === 'required' ? (
                    <input
                        className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                        type='text'
                        maxLength={30}
                        name='Phone'
                        id='phone_number'
                        placeholder='Phone Number*'
                        required
                    />
                ) : phone === 'notRequired' ? (
                    <input
                        className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                        type='text'
                        maxLength={30}
                        name='Phone'
                        id='phone_number'
                        placeholder='Phone Number'
                    />
                ) : (
                    ''
                )}
            </div>

            {message && message === 'required' ? (
                <textarea
                    className='footer-color w-100 pl-2 input-form-border-textarea py-3 placeholder-opacity my-2'
                    name={`${demoRequest ? 'Demo Message' : 'Contact Message'}`}
                    id='textarea'
                    maxLength={2000}
                    placeholder={messageValue + '*'}
                    required></textarea>
            ) : message === 'notRequired' ? (
                <textarea
                    className='footer-color w-100 pl-2 input-form-border-textarea py-3 placeholder-opacity my-2'
                    id='textarea'
                    name={`${demoRequest ? 'Demo Message' : 'Contact Message'}`}
                    maxLength={2000}
                    placeholder={messageValue}></textarea>
            ) : (
                ''
            )}

            {/* Zoominfo  binding by formID*/}
            {formId === 'zoominfo-form-with-phone' ? (
                <ZoominfoFields includePhone={true} />
            ) : formId === 'zoominfo-default-form' ? (
                <ZoominfoFields />
            ) : (
                ''
            )}

            {/* Box requests */}

            {type === 'box' && (
                <Fragment>
                    <input name='License' id='license' className='d-none' />
                    <input name='downloadURL' id='downloadURL' className='d-none' />
                    <input name='Expiration Date' id='expiration_date' className='d-none' />
                    <input name='Timestamp' id='timestamp' className='d-none' />
                </Fragment>
            )}

            {/* Trial requests */}

            {type === 'trial' && (
                <Fragment>
                    <input name='Trial Source' defaultValue={trial_source} className='d-none' />
                    <input name='Timestamp' id='timestamp' className='d-none' />
                    <input name='License' id='license' className='d-none' />
                    <input name='downloadURL' id='downloadURL' className='d-none' />
                    <input name='Expiration Date' id='expiration_date' className='d-none' />
                </Fragment>
            )}

            {/* Contact US requests */}

            {type === 'contactUs' && (
                <Fragment>
                    <input
                        type='text'
                        className='d-none'
                        id='contact_source'
                        name='Contact Source'
                        defaultValue={contactSource}
                    />
                    <input name='Timestamp' id='timestamp' className='d-none' />
                </Fragment>
            )}

            {/* Default fields Always Hidden - Always Used */}
            <input name='GA Client ID' type='hidden' className='d-none' id='ga_client_id' />

            <input type='hidden' name='Splunk ID' id='splunk_id' className='d-none' />

            <input type='text' className='d-none' id='country_name' name='Country Name' />

            {/* Terms of use */}
            <div className='m-2 mb-4'>
                <label className='d-none' htmlFor='Terms Of Use'>
                    {' '}
                    Terms of use and Privacy Policy
                </label>
                <input type='checkbox' id='terms_of_use' name='Terms Of Use' required />{' '}
                <span className='pl-1'>
                    By submitting the form, you agree to our{' '}
                    <a className='link-text' href='/legals/terms-and-conditions/'>
                        Terms of Use{' '}
                    </a>
                    and{' '}
                    <a className='link-text' href='/legals/policy/'>
                        Privacy Policy
                    </a>
                </span>
            </div>
            {/* Recaptcha */}
            {/* Trigger V2 only on lower scores */}
            {disabledButton && (
                <ReCAPTCHA
                    sitekey='6LfNhSEUAAAAAL9OJM7RO856GguxnnKDM-uveavH'
                    onChange={reCAPTCHATriggered}
                />
            )}
            <div>
                <input
                    className={`${
                        disabledButton && 'mt-5'
                    } bg-red text-white  br-21 monitoring-guide-cta-padding border-0`}
                    id={inputId}
                    type='submit'
                    value={inputValue}
                    disabled={disabledButton}></input>
            </div>
            <div
                className='g-recaptcha'
                data-sitekey='6Ldy0-UZAAAAAJT6bzr6y5NXI9lQ7XJ14O3o6tjF'
                data-size='invisible'></div>
        </form>
    )
}

export default Form

Form.propTypes = {
    formId: PropTypes.string,
    action: PropTypes.string,
    onSubmit: PropTypes.func,
    validateEmailFlag: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    jobTitle: PropTypes.string,
    country: PropTypes.string,
    phone: PropTypes.string,
    message: PropTypes.string,
    messageValue: PropTypes.string,
    inputId: PropTypes.string,
    inputValue: PropTypes.string,
    trial_source: PropTypes.string,
    type: PropTypes.string,
    contactSource: PropTypes.string,
    demoRequest: PropTypes.bool,
}
